<template>
    <div ref="container">
        <page-header :title="`판매할 상품의 종류를\n알려주세요.`"></page-header>

        <div class="ui-border-line ui-h-0 ui-mb-5" />

        <tag-box>
            <li
                v-for="(row, key) in $config.constant.productTypes"
                :key="key"
                @click="() => formData.productType = row"
                :class="{ on: formData.productType === row}"
            >{{row}}</li>
            <li
                @click="() => $refs.genreEtcModal.show()"
                :class="{ on: !formData.productType && !!formData.productTypeEtc }"
            >직접입력</li>
        </tag-box>

        <modal
            ref="genreEtcModal"
            title="직접 입력해주세요"
            class="etc"
            :showCb="() => modalData.productTypeEtc = formData.productTypeEtc"
            confirm="등록"
            :confirmCb="modal => {
                formData.productTypeEtc = modalData.productTypeEtc
                formData.productType = ''
                modal.hide()
            }"
        >
            <div slot="body">
                <div class="ui-inputbox etc">
                    <input type="text" v-model="modalData.productTypeEtc" placeholder="" />
                </div>
            </div>
        </modal>

        <div class="ui-border-line ui-h-0 ui-mb-5" />

        <footer-box submitText="다음" :submitCb="() => nextStep()" :submitDisabled="!validate"></footer-box>
    </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'
import Modal from '@/components/common/Modal'
import TagBox from '@/components/common/TagBox'
export default {
    components: {
        PageHeader,
        FooterBox,
        Modal,
        TagBox,
    },
    data() {
        const postOffer = this.$store.state.postOffer

        let productType = postOffer.productType
        let productTypeEtc = ''

        if (productType && this.$config.constant.productTypes.indexOf(productType) === -1) {
            productTypeEtc = productType
            productType = ''
        }

        return {
            formData: {
                productType,
                productTypeEtc,
            },
            modalData: {
                productTypeEtc: '',
            },
        }
    },
    computed: {
        step: {
            get() {
                return this.$route.params.step
            },
        },
        postOffer: {
            get() {
                return this.$store.state.postOffer
            },
            set(val) {
                this.$store.commit('setPostOffer', val)
            },
        },
        validate: {
            get() {
                if (this.formData.productType) {
                    return true
                }

                return !this.formData.productType && !!this.formData.productTypeEtc
            },
        },
    },
    methods: {
        nextStep() {
            const productType = this.formData.productType ? this.formData.productType : this.formData.productTypeEtc
            this.postOffer = { productType }
            this.$parent.nextStep()
        },
        scrollDown() {
            this.$nextTick().then(() => {
                this.$refs?.container?.scroll?.({
                    top: this.$refs?.container?.scrollHeight || 0,
                    behavior: 'smooth',
                })
            })
        },
    },
}
</script>

<style lang="scss" scoped>
</style>
