var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container" },
    [
      _c("page-header", {
        attrs: { title: "판매할 상품의 종류를\n알려주세요." }
      }),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-5" }),
      _c(
        "tag-box",
        [
          _vm._l(_vm.$config.constant.productTypes, function(row, key) {
            return _c(
              "li",
              {
                key: key,
                class: { on: _vm.formData.productType === row },
                on: {
                  click: function() {
                    return (_vm.formData.productType = row)
                  }
                }
              },
              [_vm._v(_vm._s(row))]
            )
          }),
          _c(
            "li",
            {
              class: {
                on: !_vm.formData.productType && !!_vm.formData.productTypeEtc
              },
              on: {
                click: function() {
                  return _vm.$refs.genreEtcModal.show()
                }
              }
            },
            [_vm._v("직접입력")]
          )
        ],
        2
      ),
      _c(
        "modal",
        {
          ref: "genreEtcModal",
          staticClass: "etc",
          attrs: {
            title: "직접 입력해주세요",
            showCb: function() {
              return (_vm.modalData.productTypeEtc =
                _vm.formData.productTypeEtc)
            },
            confirm: "등록",
            confirmCb: function(modal) {
              _vm.formData.productTypeEtc = _vm.modalData.productTypeEtc
              _vm.formData.productType = ""
              modal.hide()
            }
          }
        },
        [
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c("div", { staticClass: "ui-inputbox etc" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.modalData.productTypeEtc,
                    expression: "modalData.productTypeEtc"
                  }
                ],
                attrs: { type: "text", placeholder: "" },
                domProps: { value: _vm.modalData.productTypeEtc },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.modalData,
                      "productTypeEtc",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ])
        ]
      ),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-5" }),
      _c("footer-box", {
        attrs: {
          submitText: "다음",
          submitCb: function() {
            return _vm.nextStep()
          },
          submitDisabled: !_vm.validate
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }